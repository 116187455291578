.alert-danger {
    @apply bg-rose-100 text-rose-700 p-3 mb-2 border-l-4 border-rose-600 text-sm font-medium;
  }
  .alert-success {
    @apply bg-indigo-100 text-indigo-900 p-3 mb-2 text-sm font-medium border-l-4 border-indigo-600;
  }

  .pagination-link {
    @apply border border-gray-800 text-gray-500 text-sm font-medium block py-2 px-4 bg-gray-900 border-r-0;
  }
  .pagination-link-light {
    @apply border border-gray-400 text-gray-700 text-sm font-medium block py-2 px-4 bg-white border-r-0;
  }
  .pagination-li:last-child {
    @apply border-r border-r-gray-400
  }
  .size {
    @apply border border-gray-400 ml-2 px-3 py-1.5 mt-2 uppercase cursor-pointer rounded text-gray-400 text-sm;
  }
  .right-heading {
    @apply capitalize my-2 text-gray-400 text-base;
  }
  .preivew-image {
    @apply w-full max-h-[190px] h-full rounded-md overflow-hidden mt-3;
  }
  .ql-toolbar.ql-snow {
    @apply border-none bg-gray-900 !important;
  }
  .ql-container.ql-snow {
    @apply border-none bg-gray-700 !important;
  }
  .my-container {
    @apply max-w-screen-xl w-full mx-auto px-6;
  }
  .flex-y {
    @apply flex items-center;
  }
  .heading {
    @apply text-lg capitalize font-medium;
  }
  .error {
    @apply block mt-1.5 text-rose-600 font-medium text-sm; 
  }
  .account-list {
    @apply flex items-center px-3 py-3.5 rounded-md;
  }
  .account-list-title {
    @apply block ml-3 font-medium text-sm text-gray-700 capitalize;
  }
  a.active {
    @apply bg-gray-50;
  }
  .description h1 {
      @apply text-4xl capitalize;
  }
  .description h2 {
      @apply text-3xl capitalize;
  }
  .description h3 {
      @apply text-2xl capitalize;
  }
  .description h4 {
    @apply text-xl capitalize;
}
  

  /* Swiper styles */
  .slide {
  @apply relative;
  }
  .slide-img {
    @apply absolute inset-0 bg-no-repeat bg-cover;
  }
.swiper {
  width: 100%;
  height: 70vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

