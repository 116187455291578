.confirmation-model {
  min-height: 300px;
  width: 500px;
  background: $themeColor;
  .model-block {
    width: 100%;
    padding: 30px;
    .model-hdr {
      .cross-icon {
        &:hover {
          svg {
            stroke: #ae2744;
          }
        }
      }
    }
    .desc {
      height: 100%;
      font-size: 18px;
      font-weight: 400;
      color: $white;
      text-align: center;
    }
    .actions {
      gap: 30px;
      .btn {
        min-width: 160px;
        border-color: $white;
        font-size: 20px;
        font-weight: 500px;
      }
    }
  }
}
