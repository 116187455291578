.lading-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  .wrap {
    .page-title {
      margin-bottom: 30px;
    }
    .table-blk {
      height: 100%;
      background: #ffffff;
      border-radius: 10px;
      margin: 30px 0;
      .action {
        margin: 20px 30px;
        .btn-new {
          border-radius: 8px;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      .tbl {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        &::-webkit-scrollbar {
          height: 6px !important;
        }
        .sc-dmctIk,
        .WQNxq {
          &::-webkit-scrollbar {
            height: 6px !important;
          }
        }
        //   Table Header
        .rdt_TableHead {
          .iSAVrt {
            min-height: 100px !important;
          }
          .rdt_TableHeadRow {
            .rdt_TableCol {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #262626;
              align-items: center;
              justify-content: start;
            }
          }
        }

        .cohCfu,
        .eBWcrR {
          min-height: 82px !important;
        }
        //   Table Body
        .rdt_TableBody {
          .dwPlXY {
            min-height: 82px !important;
          }
          .rdt_TableRow {
            .rdt_TableCell {
              align-items: center;
              justify-content: start;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #262626;

              .status-tag {
                padding: 4px 12px;
                border-radius: 30px;
                font-weight: 600;
                border-bottom: 1px solid #323232;
                border-right: 1.5px double #323232;
                &.secondary {
                  color: #898989;
                  border: 1.5px solid #898989;
                  color: #898989;
                }
                &.success {
                  color: #00914e;
                  border: 1.5px solid #00914e;
                  color: #00914e;
                }
                &.primary {
                  color: #2095d9;
                  border: 1.5px solid #2095d9;
                  color: #2095d9;
                }
                &.danger {
                  color: #b93535;
                  border: 1.5px solid #b93535;
                  color: #b93535;
                }
              }
              .btn-approve {
                border-radius: 8px;
                font-weight: 600;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
