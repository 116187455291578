.table-row-model {
  min-height: 200px;
  width: 660px;
  background: $themeColor;
  .model-block {
    width: 100%;
    padding: 30px;
    .model-hdr {
      .cross-icon {
        &:hover {
          svg {
            stroke: #ae2744;
          }
        }
      }
    }
    .model-item {
      margin-bottom: 20px;
      border-bottom: 1px solid #eee;
      padding-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      .model-tag {
        font-size: 18px;
        font-weight: 500;
        color: $white;
        margin-right: 20px;
        flex: 0.5;
      }
      .model-val {
        flex: 1;
        font-size: 15px;
        font-weight: 400;
        color: $white;
      }
    }
  }
}
